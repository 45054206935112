import store from "@/store/index"
import eyeTest from "@/api/eyeTest"
import {Loading,MessageBox} from "element-ui"


//保存眼部检查方法
function saveEyeBuTest(type='jcjc') {
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    eyeTest.eyeBuTestSave(type)
       .then(res => {
           loadingInstance.close()
           if (res.UpdateResult) {
               if (type == 'jcjc') {
                   store.commit('isbc/upjzyz', {
                       val: true,
                       keys: type
                   })
               }else {
                   store.commit('isbc/uptjk', {
                       val: true,
                       keys: type
                   })
               }
               MessageBox('眼部检查保存成功', '提示')
           }else {
               MessageBox('眼部检查保存失败，请稍后再试', '提示')
           }
       })
}

//保存屈光检查方法
function saveRefractive() {
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    eyeTest.RefractiveSave()
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'dnyg'
                })
                MessageBox('屈光检查保存成功', '提示')
            }else {
                MessageBox('屈光检查保存失败，请稍后再试', '提示')
            }
        })
}

//保存眼压数据方法
function saveEyeYa() {
    let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
    });
    eyeTest.eyeYaTestSave()
        .then(res => {
            loadingInstance.close()
            if (res.UpdateResult) {
                store.commit('isbc/upjzyz', {
                    val: true,
                    keys: 'yyjc'
                })
                MessageBox('眼压检查保存成功', '提示')
            }else {
                MessageBox('眼压检查保存失败，请稍后再试', '提示')
            }
        })
}

export default {
    saveEyeBuTest,
    saveRefractive,
    saveEyeYa
}
